// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "fixed",
      "bottom-0",
      "left-0",
      "w-full",
      "md:h-20",
      "h-auto",
      "bg-white",
      "z-[101]",
      "flex",
      "items-center",
      "shadow-[0_2px_30px_0_rgba(30,32,34,0.15)]"
    ]);

var innerContainer = Cx.cx([
      "w-full",
      "h-full",
      "flex",
      "flex-wrap",
      "gap-2",
      "md:justify-center",
      "justify-between",
      "items-center",
      "md:overflow-hidden",
      "mx-auto",
      "md:px-7",
      "overflow-x-auto",
      "py-2",
      "px-3",
      "lg:max-w-screen-xl"
    ]);

var approvalContainer = Cx.cx([
      "flex",
      "md:ml-auto",
      "shrink-0",
      "items-center",
      "justify-center",
      "max-md:w-full"
    ]);

var toggleContainer = Cx.cx([
      "flex",
      "h-10",
      "px-4",
      "py-2",
      "rounded",
      "bg-gray-100",
      "md:mr-2"
    ]);

var inactiveText = Cx.cx([
      "mt-0",
      "text-gray-700",
      "text-sm",
      "leading-5",
      "mr-4"
    ]);

var saveButton = "mr-5";

var actionButtonsContainer = "flex";

export {
  container ,
  innerContainer ,
  approvalContainer ,
  saveButton ,
  actionButtonsContainer ,
  toggleContainer ,
  inactiveText ,
}
/* container Not a pure module */
