// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProviderEditTab from "../../ProviderEditTab.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as DashboardIndexCss from "../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as ProviderAllSettings from "./all/ProviderAllSettings.res.js";
import * as ProviderTsdSettings from "./tsds/ProviderTsdSettings.res.js";
import * as ProviderProjectSettings from "./projects/ProviderProjectSettings.res.js";

function ProviderEditSettings(props) {
  var updateProvider = props.updateProvider;
  var userRole = props.userRole;
  var provider = props.provider;
  var initialState = React.useMemo((function () {
          var tmp;
          tmp = userRole === "Admin" ? "All" : "ProjectSettings";
          return {
                  selectedOption: tmp
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    selectedOption: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.selectedOption;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(H1.make, {
                                          className: DashboardIndexCss.title,
                                          children: "Settings: " + provider.name
                                        }),
                                    className: DashboardIndexCss.header
                                  }),
                              className: DashboardNavbarCss.headerContainer
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            Belt_Array.mapWithIndex(ProviderEditTab.SettingsNav.Tab.tabs, (function (index, tab) {
                                                    var match = tab.tab;
                                                    if (match === "TsdSettings") {
                                                      return JsxRuntime.jsx("div", {
                                                                  children: JsxRuntime.jsx(Link.make, {
                                                                        href: "#",
                                                                        className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                                        children: tab.title
                                                                      }),
                                                                  className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "ToggleOptions",
                                                                            _0: tab.tab
                                                                          });
                                                                    })
                                                                }, String(index));
                                                    } else if (match === "All") {
                                                      if (userRole === "Admin") {
                                                        return JsxRuntime.jsx("div", {
                                                                    children: JsxRuntime.jsx(Link.make, {
                                                                          href: "#",
                                                                          className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                                          children: tab.title
                                                                        }),
                                                                    className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "ToggleOptions",
                                                                              _0: tab.tab
                                                                            });
                                                                      })
                                                                  }, String(index));
                                                      } else {
                                                        return null;
                                                      }
                                                    } else {
                                                      return JsxRuntime.jsx("div", {
                                                                  children: JsxRuntime.jsx(Link.make, {
                                                                        href: "#",
                                                                        className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                                        children: tab.title
                                                                      }),
                                                                  className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "ToggleOptions",
                                                                            _0: tab.tab
                                                                          });
                                                                    })
                                                                }, String(index));
                                                    }
                                                  })),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Routes_Provider.show(provider.slug),
                                                        className: DashboardNavbarCss.tabItem,
                                                        children: "View Live Provider Page"
                                                      }),
                                                  className: DashboardNavbarCss.viewLive
                                                })
                                          ],
                                          className: DashboardNavbarCss.barContainer
                                        }),
                                    className: DashboardNavbarCss.barWrapperGeneral
                                  }),
                              className: DashboardNavbarCss.barWrapperMargin
                            })
                      ],
                      className: DashboardNavbarCss.subnavContainer
                    }),
                match$1 === "TsdSettings" ? JsxRuntime.jsx(ProviderTsdSettings.make, {
                        provider: provider
                      }) : (
                    match$1 === "All" ? JsxRuntime.jsx(ProviderAllSettings.make, {
                            provider: provider,
                            updateProvider: updateProvider
                          }) : JsxRuntime.jsx(ProviderProjectSettings.make, {
                            provider: provider,
                            live: props.live,
                            updateProvider: updateProvider
                          })
                  )
              ]
            });
}

var Css;

var make = ProviderEditSettings;

export {
  Css ,
  make ,
}
/* A Not a pure module */
