// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../../../libs/Date.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as Picture from "../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../../../../libs/Cloudinary.res.js";
import * as Pagination from "../../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableHeader from "../../../../../../styleguide/components/Table/TableHeader.res.js";
import * as ProviderUser from "../../../../../../models/ProviderUser.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as IconUserAvatar from "../../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as TableHeaderCell from "../../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as Api_ProviderUser from "../../../../../../api/provider-users/Api_ProviderUser.res.js";
import * as DashboardTableCss from "../../../../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as ProviderEditManager from "../edit-manager/ProviderEditManager.res.js";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";
import * as ProviderEditInviteManager from "../invite-manager/ProviderEditInviteManager.res.js";

function ProviderEditProviderUsers(props) {
  var updateProviderUsers = props.updateProviderUsers;
  var providerUsers = props.providerUsers;
  var provider = props.provider;
  var container = React.useRef(null);
  var initialState_status = {
    TAG: "Ready",
    _0: providerUsers
  };
  var initialState_currentPage = providerUsers.currentPage;
  var initialState_totalPages = providerUsers.totalPages;
  var initialState = {
    status: initialState_status,
    currentPage: initialState_currentPage,
    totalPages: initialState_totalPages,
    openDestroyConfirm: false,
    openResendConfirm: false,
    currentProviderUserId: undefined,
    currentProviderUser: undefined,
    openInviteManager: false,
    openEdit: false
  };
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchUsers" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_ProviderUser.fetchProviderUsersDashboard(param.state.currentPage, provider.id), (function (x) {
                                      if (x.TAG === "Ok") {
                                        var res = x._0;
                                        dispatch({
                                              TAG: "SucceedUsersFetch",
                                              _0: res
                                            });
                                        return updateProviderUsers(res);
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditProviderUsers",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditProviderUsers.make"
                                          }, "ProviderUsersDashboard::FetchUsers::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailUsersFetch");
                                    }));
                            })
                        };
              case "FailUsersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedUsersFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: state.openDestroyConfirm,
                            openResendConfirm: state.openResendConfirm,
                            currentProviderUserId: state.currentProviderUserId,
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: state.openInviteManager,
                            openEdit: state.openEdit
                          }
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: false,
                            openResendConfirm: state.openResendConfirm,
                            currentProviderUserId: undefined,
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: state.openInviteManager,
                            openEdit: state.openEdit
                          }
                        };
              case "ToggleInviteManager" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: state.openDestroyConfirm,
                            openResendConfirm: state.openResendConfirm,
                            currentProviderUserId: state.currentProviderUserId,
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: !state.openInviteManager,
                            openEdit: state.openEdit
                          }
                        };
              case "CloseResendConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: state.openDestroyConfirm,
                            openResendConfirm: false,
                            currentProviderUserId: undefined,
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: state.openInviteManager,
                            openEdit: state.openEdit
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedUsersFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.providerUsers
                            },
                            currentPage: res.providerUsers.currentPage,
                            totalPages: res.providerUsers.totalPages,
                            openDestroyConfirm: state.openDestroyConfirm,
                            openResendConfirm: state.openResendConfirm,
                            currentProviderUserId: state.currentProviderUserId,
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: state.openInviteManager,
                            openEdit: state.openEdit
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingUsers") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingUsers",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              openDestroyConfirm: state.openDestroyConfirm,
                              openResendConfirm: state.openResendConfirm,
                              currentProviderUserId: state.currentProviderUserId,
                              currentProviderUser: state.currentProviderUser,
                              openInviteManager: state.openInviteManager,
                              openEdit: state.openEdit
                            },
                            _1: (function (param) {
                                param.dispatch("FetchUsers");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: true,
                            openResendConfirm: state.openResendConfirm,
                            currentProviderUserId: Caml_option.some(action._0),
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: state.openInviteManager,
                            openEdit: state.openEdit
                          }
                        };
              case "ToggleEdit" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: state.openDestroyConfirm,
                            openResendConfirm: state.openResendConfirm,
                            currentProviderUserId: state.currentProviderUserId,
                            currentProviderUser: action._0,
                            openInviteManager: state.openInviteManager,
                            openEdit: !state.openEdit
                          }
                        };
              case "OpenResendConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            openDestroyConfirm: state.openDestroyConfirm,
                            openResendConfirm: true,
                            currentProviderUserId: Caml_option.some(action._0),
                            currentProviderUser: state.currentProviderUser,
                            openInviteManager: state.openInviteManager,
                            openEdit: state.openEdit
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  if (state.openEdit) {
    var x = state.currentProviderUser;
    tmp = x !== undefined ? JsxRuntime.jsx(ProviderEditManager.make, {
            providerUser: Belt_Option.getExn(x),
            providerUsers: providerUsers,
            remove: (function () {
                dispatch({
                      TAG: "OpenDestroyConfirm",
                      _0: Belt_Option.getExn(Caml_option.some(x.id))
                    });
              }),
            close: (function () {
                dispatch({
                      TAG: "ToggleEdit",
                      _0: undefined
                    });
              }),
            updateProviderUsers: updateProviderUsers
          }) : null;
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Button.make, {
                                  size: "SM",
                                  color: "Primary",
                                  disabled: state.openInviteManager,
                                  busy: state.openInviteManager,
                                  onClick: (function (param) {
                                      dispatch("ToggleInviteManager");
                                    }),
                                  children: "Invite Manager"
                                }),
                            className: ProviderEditContainersCss.ProviderUsers.header
                          }),
                      JsxRuntime.jsx("section", {
                            children: JsxRuntime.jsxs(Table.make, {
                                  className: DashboardTableCss.table,
                                  children: [
                                    JsxRuntime.jsx(TableHeader.make, {
                                          children: JsxRuntime.jsxs(TableRow.make, {
                                                children: [
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: ""
                                                      }),
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: "Name"
                                                      }),
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: "Email"
                                                      }),
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: "Status"
                                                      }),
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: "Role"
                                                      }),
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: "Invited"
                                                      }),
                                                  JsxRuntime.jsx(TableHeaderCell.make, {
                                                        children: ""
                                                      })
                                                ]
                                              })
                                        }),
                                    JsxRuntime.jsx(TableBody.make, {
                                          children: Belt_Array.mapWithIndex(providerUsers.users, (function (index, user) {
                                                  var match = user.profilePic;
                                                  var tmp;
                                                  if (match === "") {
                                                    tmp = JsxRuntime.jsx(IconUserAvatar.make, {
                                                          size: "XL"
                                                        });
                                                  } else {
                                                    var profileImage = Cloudinary.imageUrl(user.profilePic);
                                                    tmp = JsxRuntime.jsx(Picture.make, {
                                                          src: profileImage,
                                                          large: [
                                                            40,
                                                            40
                                                          ],
                                                          crop: "Thumb",
                                                          className: ProviderEditContainersCss.ProviderUsers.profilePic
                                                        });
                                                  }
                                                  var match$1 = user.status;
                                                  var tmp$1;
                                                  tmp$1 = match$1 === "Accepted" ? JsxRuntime.jsx(Control.make, {
                                                          className: DashboardFilterCss.dropdownBodyRow,
                                                          children: JsxRuntime.jsx("a", {
                                                                children: "Edit",
                                                                className: DashboardFilterCss.dropdownBodyLink,
                                                                href: "#",
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "ToggleEdit",
                                                                          _0: user
                                                                        });
                                                                  })
                                                              })
                                                        }) : null;
                                                  var match$2 = user.status;
                                                  var tmp$2;
                                                  tmp$2 = match$2 === "Pending" ? JsxRuntime.jsx(Control.make, {
                                                          className: DashboardFilterCss.dropdownBodyRow,
                                                          children: JsxRuntime.jsx("a", {
                                                                children: "Resend",
                                                                className: DashboardFilterCss.dropdownBodyLink,
                                                                href: "#",
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "OpenResendConfirm",
                                                                          _0: user.id
                                                                        });
                                                                  })
                                                              })
                                                        }) : null;
                                                  return JsxRuntime.jsxs(TableRow.make, {
                                                              children: [
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: tmp
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      textSize: "Primary",
                                                                      children: user.name
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: user.email
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: ProviderUser.statusToString(user.status)
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: ProviderUser.roleToString(user.providerRole)
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: $$Date.Naive.format(user.invitedAt, "MM/dd/yyyy")
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: JsxRuntime.jsx("div", {
                                                                            children: JsxRuntime.jsxs(Popover.make, {
                                                                                  children: [
                                                                                    JsxRuntime.jsx(Popover.Trigger.make, {
                                                                                          children: JsxRuntime.jsx(Control.make, {
                                                                                                children: JsxRuntime.jsx(IconMenu.make, {
                                                                                                      size: "MD",
                                                                                                      color: "Teal"
                                                                                                    })
                                                                                              })
                                                                                        }),
                                                                                    JsxRuntime.jsxs(Dropdown.Body.make, {
                                                                                          position: {
                                                                                            TAG: "Below",
                                                                                            _0: "RightEdge"
                                                                                          },
                                                                                          className: DashboardFilterCss.dropdownBody,
                                                                                          children: [
                                                                                            tmp$1,
                                                                                            JsxRuntime.jsx(Control.make, {
                                                                                                  className: DashboardFilterCss.dropdownBodyRow,
                                                                                                  children: JsxRuntime.jsx("a", {
                                                                                                        children: "Remove",
                                                                                                        className: DashboardFilterCss.dropdownBodyLink,
                                                                                                        href: "#",
                                                                                                        onClick: (function (param) {
                                                                                                            dispatch({
                                                                                                                  TAG: "OpenDestroyConfirm",
                                                                                                                  _0: user.id
                                                                                                                });
                                                                                                          })
                                                                                                      })
                                                                                                }),
                                                                                            tmp$2
                                                                                          ]
                                                                                        })
                                                                                  ]
                                                                                })
                                                                          })
                                                                    })
                                                              ]
                                                            }, String(index));
                                                }))
                                        })
                                  ]
                                }),
                            className: DashboardTableCss.tableWrapper
                          }),
                      state.totalPages > 1 ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: DashboardTableCss.pagination
                            }) : null,
                      state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Are you sure?",
                                            className: ProviderEditContainersCss.deleteConfirmationText
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "SM",
                                                    color: "Gray",
                                                    onClick: (function (param) {
                                                        dispatch("CloseDestroyConfirm");
                                                      }),
                                                    children: "Cancel"
                                                  }),
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "SM",
                                                    color: "Pink",
                                                    onClick: (function (param) {
                                                        var providerUserId = state.currentProviderUserId;
                                                        if (providerUserId !== undefined) {
                                                          return $$Promise.wait(Api_ProviderUser.destroy(Caml_option.valFromOption(providerUserId), {
                                                                          page: state.currentPage
                                                                        }), (function (x) {
                                                                        if (x.TAG !== "Ok") {
                                                                          return SentryLogger.error1({
                                                                                      rootModule: "ProviderEditProviderUsers",
                                                                                      subModulePath: /* [] */0,
                                                                                      value: "make",
                                                                                      fullPath: "ProviderEditProviderUsers.make"
                                                                                    }, "DestroyProviderUser::Error", [
                                                                                      "Error",
                                                                                      x._0
                                                                                    ]);
                                                                        }
                                                                        dispatch("CloseDestroyConfirm");
                                                                        updateProviderUsers(x._0);
                                                                      }));
                                                        } else {
                                                          return dispatch("CloseDestroyConfirm");
                                                        }
                                                      }),
                                                    children: "Yes, delete it"
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.deleteConfirmationButtons
                                          })
                                    ],
                                    className: ProviderEditContainersCss.deleteConfirmationDialog
                                  }),
                              className: ProviderEditContainersCss.deleteConfirmationOverlay,
                              onClick: (function ($$event) {
                                  $$event.stopPropagation();
                                })
                            }) : null,
                      state.openResendConfirm ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Resend invitation?",
                                            className: ProviderEditContainersCss.deleteConfirmationText
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "SM",
                                                    color: "Gray",
                                                    onClick: (function (param) {
                                                        dispatch("CloseResendConfirm");
                                                      }),
                                                    children: "Cancel"
                                                  }),
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "SM",
                                                    color: "Pink",
                                                    onClick: (function (param) {
                                                        var providerUserId = state.currentProviderUserId;
                                                        if (providerUserId !== undefined) {
                                                          return $$Promise.wait(Api_ProviderUser.resendInvitation(Caml_option.valFromOption(providerUserId), {
                                                                          page: state.currentPage
                                                                        }), (function (x) {
                                                                        if (x.TAG !== "Ok") {
                                                                          return SentryLogger.error1({
                                                                                      rootModule: "ProviderEditProviderUsers",
                                                                                      subModulePath: /* [] */0,
                                                                                      value: "make",
                                                                                      fullPath: "ProviderEditProviderUsers.make"
                                                                                    }, "ResendProviderUserInvitation::Error", [
                                                                                      "Error",
                                                                                      x._0
                                                                                    ]);
                                                                        }
                                                                        dispatch("CloseResendConfirm");
                                                                        updateProviderUsers(x._0);
                                                                      }));
                                                        } else {
                                                          return dispatch("CloseResendConfirm");
                                                        }
                                                      }),
                                                    children: "Yes, resend it"
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.deleteConfirmationButtons
                                          })
                                    ],
                                    className: ProviderEditContainersCss.deleteConfirmationDialog
                                  }),
                              className: ProviderEditContainersCss.deleteConfirmationOverlay,
                              onClick: (function ($$event) {
                                  $$event.stopPropagation();
                                })
                            }) : null,
                      state.openInviteManager ? JsxRuntime.jsx(ProviderEditInviteManager.make, {
                              providerId: provider.id,
                              providerUsers: providerUsers,
                              close: (function () {
                                  dispatch("ToggleInviteManager");
                                }),
                              updateProviderUsers: updateProviderUsers
                            }) : null,
                      tmp
                    ],
                    className: ProviderEditContainersCss.generalContainer
                  }),
              className: ProviderEditContainersCss.container
            });
}

var Css;

var TableCss;

var Filters;

var make = ProviderEditProviderUsers;

export {
  Css ,
  TableCss ,
  Filters ,
  make ,
}
/* Date Not a pure module */
