// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Col from "../../../../../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../../../../../styleguide/components/Grid/Row/Row.res.js";
import * as Label from "../../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as Api_ProviderUser from "../../../../../../../api/provider-users/Api_ProviderUser.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ProviderEditInviteManagerFormCss from "./ProviderEditInviteManagerFormCss.res.js";

var validators_firstName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.firstName.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.firstName
              };
      } else {
        return {
                TAG: "Error",
                _0: "First Name is required."
              };
      }
    })
};

var validators_lastName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.lastName.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.lastName
              };
      } else {
        return {
                TAG: "Error",
                _0: "Last Name is required."
              };
      }
    })
};

var validators_email = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.email.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.email
              };
      } else {
        return {
                TAG: "Error",
                _0: "Email is required."
              };
      }
    })
};

var validators_providerRole = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.providerRole.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.providerRole
              };
      } else {
        return {
                TAG: "Error",
                _0: "Role is required"
              };
      }
    })
};

var validators = {
  firstName: validators_firstName,
  lastName: validators_lastName,
  email: validators_email,
  providerRole: validators_providerRole
};

function initialFieldsStatuses(_input) {
  return {
          firstName: "Pristine",
          lastName: "Pristine",
          email: "Pristine",
          providerRole: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            firstName: "Pristine",
            lastName: "Pristine",
            email: "Pristine",
            providerRole: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.firstName;
  var tmp;
  tmp = typeof match !== "object" ? validators.firstName.validate(input) : match._0;
  var match$1 = fieldsStatuses.lastName;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.lastName.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.email;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.email.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.providerRole;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.providerRole.validate(input) : match$3._0;
  var firstNameResult = tmp;
  var firstNameResult$1;
  if (firstNameResult.TAG === "Ok") {
    var lastNameResult = tmp$1;
    if (lastNameResult.TAG === "Ok") {
      var emailResult = tmp$2;
      if (emailResult.TAG === "Ok") {
        var providerRoleResult = tmp$3;
        if (providerRoleResult.TAG === "Ok") {
          return {
                  TAG: "Valid",
                  output: {
                    firstName: firstNameResult._0,
                    lastName: lastNameResult._0,
                    email: emailResult._0,
                    providerRole: providerRoleResult._0
                  },
                  fieldsStatuses: {
                    firstName: {
                      TAG: "Dirty",
                      _0: firstNameResult,
                      _1: "Shown"
                    },
                    lastName: {
                      TAG: "Dirty",
                      _0: lastNameResult,
                      _1: "Shown"
                    },
                    email: {
                      TAG: "Dirty",
                      _0: emailResult,
                      _1: "Shown"
                    },
                    providerRole: {
                      TAG: "Dirty",
                      _0: providerRoleResult,
                      _1: "Shown"
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        firstNameResult$1 = firstNameResult;
      } else {
        firstNameResult$1 = firstNameResult;
      }
    } else {
      firstNameResult$1 = firstNameResult;
    }
  } else {
    firstNameResult$1 = firstNameResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            firstName: {
              TAG: "Dirty",
              _0: firstNameResult$1,
              _1: "Shown"
            },
            lastName: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            email: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            providerRole: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurFirstNameField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.firstName, validators_firstName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: status,
                                  lastName: init.lastName,
                                  email: init.email,
                                  providerRole: init.providerRole
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLastNameField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.lastName, validators_lastName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: status,
                                  email: init.email,
                                  providerRole: init.providerRole
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurEmailField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.email, validators_email, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  email: status,
                                  providerRole: init.providerRole
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurProviderRoleField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.providerRole, validators_providerRole, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  email: init.email,
                                  providerRole: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateFirstNameField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.firstName, state.submissionStatus, validators_firstName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: status,
                                            lastName: init.lastName,
                                            email: init.email,
                                            providerRole: init.providerRole
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLastNameField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.lastName, state.submissionStatus, validators_lastName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: status,
                                            email: init.email,
                                            providerRole: init.providerRole
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateEmailField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.email, state.submissionStatus, validators_email, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            email: status,
                                            providerRole: init.providerRole
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateProviderRoleField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.providerRole, state.submissionStatus, validators_providerRole, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            email: init.email,
                                            providerRole: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                firstName: "Pristine",
                                lastName: "Pristine",
                                email: "Pristine",
                                providerRole: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                firstName: "Pristine",
                                lastName: "Pristine",
                                email: "Pristine",
                                providerRole: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateFirstName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateFirstNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLastName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLastNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateEmail: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateEmailField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateProviderRole: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProviderRoleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurFirstName: (function () {
              dispatch("BlurFirstNameField");
            }),
          blurLastName: (function () {
              dispatch("BlurLastNameField");
            }),
          blurEmail: (function () {
              dispatch("BlurEmailField");
            }),
          blurProviderRole: (function () {
              dispatch("BlurProviderRoleField");
            }),
          firstNameResult: Formality.exposeFieldResult(state.fieldsStatuses.firstName),
          lastNameResult: Formality.exposeFieldResult(state.fieldsStatuses.lastName),
          emailResult: Formality.exposeFieldResult(state.fieldsStatuses.email),
          providerRoleResult: Formality.exposeFieldResult(state.fieldsStatuses.providerRole),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.firstName;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.lastName;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.email;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.providerRole;
              if (typeof tmp$3 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var InviteManagerForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditInviteManagerForm(props) {
  var updateProviderUsers = props.updateProviderUsers;
  var close = props.close;
  var providerUsers = props.providerUsers;
  var providerId = props.providerId;
  var initialInput = React.useMemo((function () {
          return {
                  firstName: "",
                  lastName: "",
                  email: "",
                  providerRole: "member"
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_ProviderUser.create(providerId, {
                    firstName: output.firstName,
                    lastName: output.lastName,
                    email: output.email,
                    providerRole: output.providerRole,
                    page: providerUsers.currentPage
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditInviteManagerForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditInviteManagerForm.make"
                              }, "CreateProviderUser::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  updateProviderUsers(x._0);
                  close();
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match = form.status;
  var tmp;
  tmp = typeof match !== "object" || match.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        children: "Something went wrong."
                      })
                })
          ]
        });
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "provider-user-form--first-name",
                              children: "First Name"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "provider-user-form--first-name",
                              value: form.input.firstName,
                              placeholder: "Johnny",
                              onChange: (function ($$event) {
                                  form.updateFirstName((function (input, value) {
                                          return {
                                                  firstName: value,
                                                  lastName: input.lastName,
                                                  email: input.email,
                                                  providerRole: input.providerRole
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.firstNameResult)
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "provider-user-form--last-name",
                              children: "Last Name"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "provider-user-form--last-name",
                              value: form.input.lastName,
                              placeholder: "Appleseed",
                              onChange: (function ($$event) {
                                  form.updateLastName((function (input, value) {
                                          return {
                                                  firstName: input.firstName,
                                                  lastName: value,
                                                  email: input.email,
                                                  providerRole: input.providerRole
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.lastNameResult)
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "provider-user-form--email",
                              children: "Email"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "provider-user-form--email",
                              value: form.input.email,
                              placeholder: "example@datacenters.com",
                              onChange: (function ($$event) {
                                  form.updateEmail((function (input, value) {
                                          return {
                                                  firstName: input.firstName,
                                                  lastName: input.lastName,
                                                  email: value,
                                                  providerRole: input.providerRole
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.emailResult)
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "provider-user-form--provider-role",
                              children: "Role"
                            }),
                        JsxRuntime.jsxs(Select.make, {
                              id: "provider-user-form--provider-role",
                              value: form.input.providerRole,
                              onChange: (function ($$event) {
                                  form.updateProviderRole((function (input, value) {
                                          return {
                                                  firstName: input.firstName,
                                                  lastName: input.lastName,
                                                  email: input.email,
                                                  providerRole: value
                                                };
                                        }), $$event.target.value);
                                }),
                              children: [
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "member",
                                      children: "Member"
                                    }, "provider-role-member"),
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "manager",
                                      children: "Manager"
                                    }, "provider-role-manager"),
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "owner",
                                      children: "Owner"
                                    }, "provider-role-owner")
                              ]
                            }),
                        tmp
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              disabled: form.submitting,
                              busy: form.submitting,
                              submit: true,
                              children: "Invite Manager"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "White",
                              busy: false,
                              submit: true,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  close();
                                }),
                              children: "Cancel"
                            })
                      ],
                      className: ProviderEditInviteManagerFormCss.buttons
                    })
              ],
              className: ProviderEditInviteManagerFormCss.form,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var Css;

var make = ProviderEditInviteManagerForm;

export {
  Css ,
  InviteManagerForm ,
  make ,
}
/* Col Not a pure module */
