// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as DashboardIndexCss from "../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as ProviderEditProviderUsers from "./managers/ProviderEditProviderUsers.res.js";

function ProviderEditPeople(props) {
  var provider = props.provider;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(H1.make, {
                                          className: DashboardIndexCss.title,
                                          children: "People: " + provider.name
                                        }),
                                    className: DashboardIndexCss.header
                                  }),
                              className: DashboardNavbarCss.headerContainer
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: "#",
                                                        className: DashboardNavbarCss.underlinedLinkItem,
                                                        children: "People"
                                                      }),
                                                  className: DashboardNavbarCss.underlinedLinkContainer
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Routes_Provider.show(provider.slug),
                                                        className: DashboardNavbarCss.tabItem,
                                                        children: "View Live Provider Page"
                                                      }),
                                                  className: DashboardNavbarCss.viewLive
                                                })
                                          ],
                                          className: DashboardNavbarCss.barContainer
                                        }),
                                    className: DashboardNavbarCss.barWrapperGeneral
                                  }),
                              className: DashboardNavbarCss.barWrapperMargin
                            })
                      ],
                      className: DashboardNavbarCss.subnavContainer
                    }),
                JsxRuntime.jsx(ProviderEditProviderUsers.make, {
                      provider: provider,
                      providerUsers: props.providerUsers,
                      updateProviderUsers: props.updateProviderUsers
                    })
              ]
            });
}

var Css;

var make = ProviderEditPeople;

export {
  Css ,
  make ,
}
/* A Not a pure module */
