// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Col from "../../../../../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../../../../../styleguide/components/Grid/Row/Row.res.js";
import * as Label from "../../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as ProviderUser from "../../../../../../../models/ProviderUser.res.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as Api_ProviderUser from "../../../../../../../api/provider-users/Api_ProviderUser.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ProviderEditInviteManagerFormCss from "../../invite-manager/components/ProviderEditInviteManagerFormCss.res.js";

var validators = {
  providerRole: {
    strategy: "OnFirstSuccessOrFirstBlur",
    validate: (function (input) {
        var match = input.providerRole.length;
        if (match !== 0) {
          return {
                  TAG: "Ok",
                  _0: input.providerRole
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Role is required"
                };
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          providerRole: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            providerRole: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.providerRole;
  var match$1;
  match$1 = typeof match !== "object" ? validators.providerRole.validate(input) : match._0;
  if (match$1.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              providerRole: match$1._0
            },
            fieldsStatuses: {
              providerRole: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              providerRole: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurProviderRoleField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.providerRole, validators.providerRole, (function (status) {
                          return {
                                  providerRole: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateProviderRoleField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.providerRole, state.submissionStatus, validators.providerRole, (function (status) {
                                    return {
                                            providerRole: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                providerRole: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                providerRole: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateProviderRole: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProviderRoleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurProviderRole: (function () {
              dispatch("BlurProviderRoleField");
            }),
          providerRoleResult: Formality.exposeFieldResult(state.fieldsStatuses.providerRole),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.providerRole;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var InviteManagerForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditManagerForm(props) {
  var updateProviderUsers = props.updateProviderUsers;
  var close = props.close;
  var remove = props.remove;
  var providerUsers = props.providerUsers;
  var providerUser = props.providerUser;
  var initialInput = React.useMemo((function () {
          return {
                  providerRole: ProviderUser.roleToDowncase(providerUser.providerRole)
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_ProviderUser.update(providerUser.id, {
                    providerRole: output.providerRole,
                    page: providerUsers.currentPage
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditManagerForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditManagerForm.make"
                              }, "CreateProviderUser::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  updateProviderUsers(x._0);
                  close();
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match = form.status;
  var tmp;
  tmp = typeof match !== "object" || match.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        children: "Something went wrong."
                      })
                })
          ]
        });
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "provider-user-form--provider-role",
                              children: "Role"
                            }),
                        JsxRuntime.jsxs(Select.make, {
                              id: "provider-user-form--provider-role",
                              value: form.input.providerRole,
                              onChange: (function ($$event) {
                                  form.updateProviderRole((function (param, value) {
                                          return {
                                                  providerRole: value
                                                };
                                        }), $$event.target.value);
                                }),
                              children: [
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "member",
                                      children: "Member"
                                    }, "provider-role-member"),
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "manager",
                                      children: "Manager"
                                    }, "provider-role-manager"),
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "owner",
                                      children: "Owner"
                                    }, "provider-role-owner")
                              ]
                            }),
                        fieldError(form.providerRoleResult)
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Button.make, {
                                      size: "MD",
                                      color: "Teal",
                                      disabled: form.submitting,
                                      busy: form.submitting,
                                      submit: true,
                                      children: "Update"
                                    }),
                                JsxRuntime.jsx(Button.make, {
                                      size: "MD",
                                      color: "White",
                                      busy: false,
                                      submit: true,
                                      onClick: (function (e) {
                                          e.preventDefault();
                                          close();
                                        }),
                                      children: "Cancel"
                                    })
                              ],
                              className: ProviderEditInviteManagerFormCss.primaryButtons
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "White",
                              busy: false,
                              submit: true,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  remove();
                                  close();
                                }),
                              children: "Remove"
                            })
                      ],
                      className: ProviderEditInviteManagerFormCss.buttons
                    }),
                tmp
              ],
              className: ProviderEditInviteManagerFormCss.form,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var Css;

var make = ProviderEditManagerForm;

export {
  Css ,
  InviteManagerForm ,
  make ,
}
/* Col Not a pure module */
