// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var generalContainer = Cx.cx([
      "no-scrollbar",
      "w-full",
      "flex",
      "gap-2",
      "justify-start",
      "flex-col",
      "md:mx-auto",
      "max-md:px-7",
      "max-sm:px-4",
      "lg:max-w-screen-xl"
    ]);

var container = Cx.cx([
      "w-full",
      "relative",
      "flex",
      "flex-col",
      "px-4",
      "py-10"
    ]);

var subText = Cx.cx([
      "text-gray-700",
      "text-sm",
      "leading-5",
      "mt-1"
    ]);

var fieldChanged = Cx.cx([
      "mx-1",
      "shadow-[0_0_0_4px_rgba(255,120,1,0.3)]"
    ]);

var toggleRow = Cx.cx([
      "mb-5",
      "flex"
    ]);

var threeInRow = Cx.cx([
      "flex",
      "flex-col",
      "md:flex-row",
      "md:mb-5",
      "mb-0",
      "gap-4"
    ]);

var threeColumn = Cx.cx([
      "w-full",
      "md:w-1/3",
      "max-sm:pb-4"
    ]);

var fourInRow = Cx.cx([
      "flex",
      "flex-col",
      "md:flex-row",
      "flex-wrap",
      "md:mb-5",
      "mb-0",
      "gap-4"
    ]);

var fourColumn = Cx.cx([
      "w-full",
      "lg:w-1/4",
      "md:w-1/3",
      "max-sm:pb-4"
    ]);

var logoUploader = Cx.cx([
      "flex",
      "flex-wrap",
      "p-5",
      "rounded",
      "items-center",
      "md:justify-start",
      "justify-center",
      "gap-5",
      "border",
      "border-dashed",
      "border-gray-300"
    ]);

var logo = Cx.cx([
      "flex",
      "items-center",
      "size-40",
      "p-5",
      "bg-gray-100"
    ]);

var note = Cx.cx([
      "text-sm",
      "text-gray-500"
    ]);

var featureWrapper = Cx.cx([
      "grid",
      "relative",
      "items-center"
    ]);

var featureCounter = Cx.cx([
      "font-bold",
      "text-2xl"
    ]);

var featuresButton = Cx.cx([
      "grid",
      "gap-5",
      "items-center",
      "mt-5",
      "grid-cols-[max-content_max-content]"
    ]);

var wrapperFeatureFields = Cx.cx([
      " flex",
      "justify-between",
      "items-center"
    ]);

var featureFields = Cx.cx([
      "grid",
      "grid-rows-[max-content_max-content]",
      "grid-cols-1",
      "px-10",
      "py-5",
      "my-5",
      "bg-gray-100",
      "gap-7"
    ]);

var featureDeleteIcon = Cx.cx([
      "flex",
      "items-center",
      "justify-center"
    ]);

var featureDeleteIconControl = Cx.cx([
      "flex",
      "items-center",
      "justify-center",
      "size-10",
      "[&_path]:hover:fill-teal",
      "[&_path]:focus:fill-teal"
    ]);

var servicesRow = Cx.cx([
      "grid",
      "md:grid-cols-3",
      "sm:grid-cols-2",
      "grid-cols-1",
      "h-auto",
      "md:bg-gray-50",
      "md:p-2.5",
      "[&_label]:inline-block",
      "md:odd:bg-white",
      "empty:hidden"
    ]);

var settingsExplanation = Cx.cx([
      "text-sm",
      "text-gray-700",
      "mb-2.5"
    ]);

var directoryServiceOption = Cx.cx([
      "text-gray-700",
      "mr-0",
      "mb-0",
      "checkbox"
    ]);

var serviceChanged = Cx.cx(["text-orange"]);

var uploader = Cx.cx([
      "grid",
      "items-center",
      "justify-center",
      "justify-items-center",
      "content-center",
      "w-full",
      "mb-10",
      "border",
      "border-dashed",
      "border-gray-300",
      "rounded",
      "font-bold",
      "h-44"
    ]);

var uploaderInner = Cx.cx([
      "grid",
      "grid-rows-[max-content_max-content]",
      "gap-4",
      "items-center",
      "justify-center",
      "justify-items-center",
      "content-center"
    ]);

var imageContainer = Cx.cx([
      "flex",
      "flex-col",
      "border",
      "border-gray-300",
      "border-solid",
      "rounded",
      "p-5",
      "mb-3"
    ]);

var imagesGrid = Cx.cx([
      "flex",
      "flex-row",
      "flex-wrap",
      "gap-5"
    ]);

var deleteConfirmationOverlay = Cx.cx([
      "flex",
      "fixed",
      "inset-0",
      "items-center",
      "justify-center",
      "text-white",
      "bg-opacity-50",
      "bg-gray-800",
      "z-[9999]"
    ]);

var deleteConfirmationDialog = Cx.cx([
      "w-72",
      "h-40",
      "bg-white",
      "rounded-lg",
      "p-5",
      "flex",
      "flex-col",
      "justify-between",
      "text-center"
    ]);

var deleteConfirmationText = Cx.cx([
      "font-bold",
      "text-black",
      "text-2xl"
    ]);

var deleteConfirmationMessage = Cx.cx([
      "text-sm",
      "text-gray-500"
    ]);

var deleteConfirmationButtons = Cx.cx([
      "gap-2.5",
      "flex",
      "justify-center"
    ]);

var assetsVideoLinkForm = Cx.cx([
      "flex",
      "flex-col",
      "gap-2.5",
      "mb-10"
    ]);

var assetsVideoLinkFormRow = Cx.cx([
      "flex",
      "flex-row",
      "flex-wrap",
      "gap-3",
      "[&>div]:max-w-96"
    ]);

var assetsVideoLinkFormSubmitted = Cx.cx([
      "grid",
      "grid-cols-[max-content_max-content]",
      "gap-2.5",
      "content-center",
      "text-teal"
    ]);

var assetsVideoLinkFormError = Cx.cx([
      "text-red",
      "text-sm",
      "mt-2"
    ]);

var assetOverlay = Cx.cx(["relative"]);

var deleteAsset = Cx.cx([
      "flex",
      "items-center",
      "justify-center",
      "absolute",
      "top-1/2",
      "left-1/2",
      "-translate-x-1/2",
      "-translate-y-1/2",
      "w-full",
      "h-full",
      "font-semibold",
      "gap-1.5",
      "opacity-0",
      "hover:opacity-60",
      "hover:bg-black",
      "hover:text-white",
      "transition-opacity"
    ]);

var assetDescription = Cx.cx([
      "flex",
      "flex-col",
      "gap-1.5",
      "items-center",
      "w-full",
      "justify-center",
      "p-1.5"
    ]);

var toggleContainer = Cx.cx([
      "flex",
      "flex-row",
      "gap-2.5",
      "justify-end",
      "w-full"
    ]);

var inactiveText = Cx.cx(["text-gray-400"]);

var assetOverlayYoutube = Cx.cx([
      "relative",
      "max-w-80",
      "overflow-hidden"
    ]);

var apiKey = Cx.cx([
      "flex",
      "lg:flex-row",
      "flex-col",
      "justify-between",
      "lg:items-center",
      "items-end",
      "py-7",
      "px-3",
      "border",
      "border-solid",
      "border-gray-200",
      "rounded",
      "overflow-x-auto"
    ]);

var apiSummary = Cx.cx([
      "flex",
      "flex-col",
      "w-full"
    ]);

var apiNumber = Cx.cx([
      "text-gray-700",
      "font-semibold",
      "cursor-pointer",
      "flex",
      "items-center",
      "gap-2"
    ]);

var apiInfo = Cx.cx([
      "text-gray-700",
      "font-semibold"
    ]);

var apiKeys = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "py-7"
    ]);

var editWebhookUrl = Cx.cx([
      "py-5",
      "pb-7"
    ]);

var apiHeader = Cx.cx([
      "flex",
      "flex-row",
      "flex-wrap",
      "justify-between",
      "items-center"
    ]);

var ApiManager = {
  apiKey: apiKey,
  apiSummary: apiSummary,
  apiNumber: apiNumber,
  apiInfo: apiInfo,
  apiKeys: apiKeys,
  editWebhookUrl: editWebhookUrl,
  apiHeader: apiHeader
};

var header = Cx.cx([
      "flex",
      "mb-5",
      "py-2.5",
      "pr-1",
      "justify-end"
    ]);

var profilePic = Cx.cx(["rounded"]);

var ProviderUsers = {
  header: header,
  profilePic: profilePic
};

var Tabs;

var Header;

var overflowVisible = "overflow-visible";

var imagesContainer = "mb-10";

var fieldRow = "mb-5";

var twoColumn = "w-1/2";

var settings = "mb-8";

var directoryTitle = "text-lg";

var gridEvenColors = "max-md:**:[.checkbox]:even:bg-gray-50";

var gridOddColors = "max-md:**:[.checkbox]:odd:bg-gray-50";

var descriptionContainer = "w-full";

var youtubeImage = "h-auto";

export {
  Tabs ,
  Header ,
  generalContainer ,
  overflowVisible ,
  imagesContainer ,
  container ,
  subText ,
  fieldRow ,
  fieldChanged ,
  toggleRow ,
  twoColumn ,
  threeInRow ,
  threeColumn ,
  fourInRow ,
  fourColumn ,
  logoUploader ,
  logo ,
  note ,
  featureWrapper ,
  featureCounter ,
  featuresButton ,
  wrapperFeatureFields ,
  featureFields ,
  featureDeleteIcon ,
  featureDeleteIconControl ,
  settings ,
  directoryTitle ,
  gridEvenColors ,
  gridOddColors ,
  servicesRow ,
  settingsExplanation ,
  directoryServiceOption ,
  serviceChanged ,
  uploader ,
  uploaderInner ,
  imageContainer ,
  imagesGrid ,
  deleteConfirmationOverlay ,
  deleteConfirmationDialog ,
  deleteConfirmationText ,
  deleteConfirmationMessage ,
  deleteConfirmationButtons ,
  assetsVideoLinkForm ,
  assetsVideoLinkFormRow ,
  assetsVideoLinkFormSubmitted ,
  assetsVideoLinkFormError ,
  assetOverlay ,
  deleteAsset ,
  assetDescription ,
  descriptionContainer ,
  toggleContainer ,
  inactiveText ,
  assetOverlayYoutube ,
  youtubeImage ,
  ApiManager ,
  ProviderUsers ,
}
/* generalContainer Not a pure module */
