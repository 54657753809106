// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProviderEditTab from "../../ProviderEditTab.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as DashboardIndexCss from "../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as ProviderEditAbout from "./about/ProviderEditAbout.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as ProviderEditAssets from "./assets/ProviderEditAssets.res.js";
import * as ProviderEditServices from "./services/ProviderEditServices.res.js";
import * as ProviderEditGeneralInfo from "./general-info/ProviderEditGeneralInfo.res.js";

function ProviderEditProviderDetails(props) {
  var updateProvider = props.updateProvider;
  var live = props.live;
  var provider = props.provider;
  var initialState = React.useMemo((function () {
          return {
                  selectedOption: "GeneralInfo"
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    selectedOption: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.selectedOption;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(H1.make, {
                                          className: DashboardIndexCss.title,
                                          children: "Edit: " + provider.name
                                        }),
                                    className: DashboardIndexCss.header
                                  }),
                              className: DashboardNavbarCss.headerContainer
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            Belt_Array.mapWithIndex(ProviderEditTab.GeneralInfoNav.Tab.tabs, (function (index, tab) {
                                                    return JsxRuntime.jsx("div", {
                                                                children: JsxRuntime.jsx(Link.make, {
                                                                      href: "#",
                                                                      className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                                      children: tab.title
                                                                    }),
                                                                className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "ToggleOptions",
                                                                          _0: tab.tab
                                                                        });
                                                                  })
                                                              }, String(index));
                                                  })),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: Routes_Provider.show(provider.slug),
                                                        className: DashboardNavbarCss.tabItem,
                                                        children: "View Live Provider Page"
                                                      }),
                                                  className: DashboardNavbarCss.viewLive
                                                })
                                          ],
                                          className: DashboardNavbarCss.barContainer
                                        }),
                                    className: DashboardNavbarCss.barWrapperGeneral
                                  }),
                              className: DashboardNavbarCss.barWrapperMargin
                            })
                      ],
                      className: DashboardNavbarCss.subnavContainer
                    }),
                match$1 === "Assets" ? JsxRuntime.jsx(ProviderEditAssets.make, {
                        provider: provider,
                        live: live,
                        updateProvider: updateProvider
                      }) : (
                    match$1 === "About" ? JsxRuntime.jsx(ProviderEditAbout.make, {
                            provider: provider,
                            live: live,
                            updateProvider: updateProvider
                          }) : (
                        match$1 === "Services" ? JsxRuntime.jsx(ProviderEditServices.make, {
                                provider: provider,
                                live: live,
                                updateProvider: updateProvider
                              }) : JsxRuntime.jsx(ProviderEditGeneralInfo.make, {
                                provider: provider,
                                live: live,
                                userRole: props.userRole,
                                updateProvider: updateProvider,
                                updateEditStatus: props.updateEditStatus
                              })
                      )
                  )
              ]
            });
}

var Css;

var make = ProviderEditProviderDetails;

export {
  Css ,
  make ,
}
/* A Not a pure module */
