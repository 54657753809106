// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var form = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var buttons = Cx.cx([
      "flex",
      "flex-row",
      "justify-between"
    ]);

var primaryButtons = Cx.cx([
      "flex",
      "flex-row"
    ]);

export {
  form ,
  buttons ,
  primaryButtons ,
}
/* form Not a pure module */
