// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var toggleContainer = Cx.cx([
      "flex",
      "h-12",
      "px-4",
      "py-2.5",
      "rounded",
      "bg-gray-50",
      "md:mr-2",
      "items-center"
    ]);

var inactiveText = Cx.cx([
      "mt-0",
      "mb-0",
      "text-gray-700",
      "text-sm",
      "leading-5",
      "mr-4"
    ]);

var actionButtonsContainer = "flex";

export {
  actionButtonsContainer ,
  toggleContainer ,
  inactiveText ,
}
/* toggleContainer Not a pure module */
