// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as IconView from "../../../../../../styleguide/icons/IconView.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";

function ProviderApiKey(props) {
  var apiKey = props.apiKey;
  var match = Hooks.useReducer({
        keyVisibility: "Password"
      }, (function (state, action) {
          var match = state.keyVisibility;
          var tmp;
          tmp = match === "Text" ? "Password" : "Text";
          return {
                  TAG: "Update",
                  _0: {
                    keyVisibility: tmp
                  }
                };
        }));
  var dispatch = match[1];
  var match$1 = match[0].keyVisibility;
  var tmp;
  tmp = match$1 === "Text" ? (function (__x) {
          return Js_string.slice(0, 80, __x);
        })(apiKey.key) : "•••••••••••••";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        "Key #" + String(props.index + 1 | 0),
                        JsxRuntime.jsx(IconView.make, {
                              size: "SM",
                              color: "Teal"
                            })
                      ],
                      className: ProviderEditContainersCss.ApiManager.apiNumber,
                      onClick: (function (param) {
                          dispatch("SetKeyVisibility");
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        "Created: " + Format(apiKey.createdAt, "MM/dd/yyyy"),
                        " • ",
                        "Expires: " + Format(apiKey.expiresAt, "MM/dd/yyyy")
                      ],
                      className: ProviderEditContainersCss.ApiManager.apiInfo
                    })
              ],
              className: ProviderEditContainersCss.ApiManager.apiSummary
            });
}

var Css;

var make = ProviderApiKey;

export {
  Css ,
  make ,
}
/* Hooks Not a pure module */
