// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as Label from "../../../../../../styleguide/forms/Label/Label.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderSetting from "../../../../../../api/provider-settings/Api_ProviderSetting.res.js";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";
import * as ProviderEditMasterDropdown from "./components/master-dropdown/ProviderEditMasterDropdown.res.js";
import * as ProviderEditAgreementButton from "./components/agreement-button/ProviderEditAgreementButton.res.js";

function ProviderAllSettings(props) {
  var updateProvider = props.updateProvider;
  var provider = props.provider;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H3.make, {
                            children: "Select Provider Settings"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: ProviderEditContainersCss.directoryTitle,
                                    children: "General Settings"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "Choose from the following settings. Changes are saved automatically.",
                                    className: ProviderEditContainersCss.settingsExplanation
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-provider-form--master",
                                                    children: "Master"
                                                  }),
                                              JsxRuntime.jsx(ProviderEditMasterDropdown.make, {
                                                    settings: provider.settings,
                                                    updateProvider: updateProvider
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.threeColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-provider-form--agreement",
                                                    children: "Agreement"
                                                  }),
                                              JsxRuntime.jsx(ProviderEditAgreementButton.make, {
                                                    settings: provider.settings,
                                                    updateProvider: updateProvider
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.threeColumn
                                          })
                                    ],
                                    className: ProviderEditContainersCss.fourInRow
                                  })
                            ],
                            className: ProviderEditContainersCss.settings
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: ProviderEditContainersCss.directoryTitle,
                                    children: "Marketplaces"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "When a box is checked below, an active provider will be featured the corresponding marketplace. Changes are saved automatically.",
                                    className: ProviderEditContainersCss.settingsExplanation
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                          id: "colocationMarketplace--" + ID.toString(provider.id),
                                          size: "SM",
                                          checked: provider.settings.colocationMarketplace,
                                          containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                          onChange: (function (param) {
                                              $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                        field: "ColocationMarketplace",
                                                        value: !provider.settings.colocationMarketplace
                                                      }), (function (x) {
                                                      if (x.TAG === "Ok") {
                                                        return updateProvider(x._0);
                                                      } else {
                                                        return SentryLogger.error1({
                                                                    rootModule: "ProviderAllSettings",
                                                                    subModulePath: /* [] */0,
                                                                    value: "make",
                                                                    fullPath: "ProviderAllSettings.make"
                                                                  }, "ProviderSettings::Update::Error", [
                                                                    "Error",
                                                                    x._0
                                                                  ]);
                                                      }
                                                    }));
                                            }),
                                          children: JsxRuntime.jsx("div", {
                                                children: "Colocation"
                                              })
                                        }),
                                    className: ProviderEditContainersCss.servicesRow
                                  })
                            ],
                            className: ProviderEditContainersCss.settings
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: ProviderEditContainersCss.directoryTitle,
                                    children: "Directories"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "When a box is checked below, an active provider will be featured the corresponding directory. Changes are saved automatically.",
                                    className: ProviderEditContainersCss.settingsExplanation
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "colocationDirectory--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.colocationDirectory,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "ColocationDirectory",
                                                          value: !provider.settings.colocationDirectory
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Colocation"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "cloudDirectory--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.cloudDirectory,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "CloudDirectory",
                                                          value: !provider.settings.cloudDirectory
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Cloud"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "internetDirectory--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.internetDirectory,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "InternetDirectory",
                                                          value: !provider.settings.internetDirectory
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Internet"
                                                })
                                          })
                                    ],
                                    className: Cx.cx([
                                          ProviderEditContainersCss.servicesRow,
                                          ProviderEditContainersCss.gridOddColors
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "bareMetalDirectory--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.bareMetalDirectory,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "BareMetalDirectory",
                                                          value: !provider.settings.bareMetalDirectory
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Bare Metal"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "networkDirectory--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.networkDirectory,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "NetworkDirectory",
                                                          value: !provider.settings.networkDirectory
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Network"
                                                })
                                          })
                                    ],
                                    className: Cx.cx([
                                          ProviderEditContainersCss.servicesRow,
                                          ProviderEditContainersCss.gridEvenColors
                                        ])
                                  })
                            ],
                            className: ProviderEditContainersCss.settings
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: ProviderEditContainersCss.directoryTitle,
                                    children: "Project Platform"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "When a box is checked below, an active provider, with agreement, will be eligible for selection in the corresponding project type. Changes are saved automatically.",
                                    className: ProviderEditContainersCss.settingsExplanation
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "colocationProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.colocationProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "ColocationProjects",
                                                          value: !provider.settings.colocationProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Colocation"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "bareMetalProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.bareMetalProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "BareMetalProjects",
                                                          value: !provider.settings.bareMetalProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Bare Metal"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "internetProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.internetProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "InternetProjects",
                                                          value: !provider.settings.internetProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Internet"
                                                })
                                          })
                                    ],
                                    className: Cx.cx([
                                          ProviderEditContainersCss.servicesRow,
                                          ProviderEditContainersCss.gridOddColors
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "cloudIaasProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.cloudIaasProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "CloudIaasProjects",
                                                          value: !provider.settings.cloudIaasProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Cloud IaaS"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "cloudServersProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.cloudServersProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "CloudServersProjects",
                                                          value: !provider.settings.cloudServersProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Cloud Servers"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "networkSdwanProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.networkSdwanProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "NetworkSdwanProjects",
                                                          value: !provider.settings.networkSdwanProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Network SD-WAN"
                                                })
                                          })
                                    ],
                                    className: Cx.cx([
                                          ProviderEditContainersCss.servicesRow,
                                          ProviderEditContainersCss.gridEvenColors
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "networkMplsProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.networkMplsProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "NetworkMplsProjects",
                                                          value: !provider.settings.networkMplsProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Network MPLS"
                                                })
                                          }),
                                      JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: "networkPrivateLineProjects--" + ID.toString(provider.id),
                                            size: "SM",
                                            checked: provider.settings.networkPrivateLineProjects,
                                            containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                            onChange: (function (param) {
                                                $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                          field: "NetworkPrivateLineProjects",
                                                          value: !provider.settings.networkPrivateLineProjects
                                                        }), (function (x) {
                                                        if (x.TAG === "Ok") {
                                                          return updateProvider(x._0);
                                                        } else {
                                                          return SentryLogger.error1({
                                                                      rootModule: "ProviderAllSettings",
                                                                      subModulePath: /* [] */0,
                                                                      value: "make",
                                                                      fullPath: "ProviderAllSettings.make"
                                                                    }, "ProviderSettings::Update::Error", [
                                                                      "Error",
                                                                      x._0
                                                                    ]);
                                                        }
                                                      }));
                                              }),
                                            children: JsxRuntime.jsx("div", {
                                                  children: "Network Private Line"
                                                })
                                          })
                                    ],
                                    className: Cx.cx([
                                          ProviderEditContainersCss.servicesRow,
                                          ProviderEditContainersCss.gridOddColors
                                        ])
                                  })
                            ],
                            className: Cx.cx([ProviderEditContainersCss.settings])
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: ProviderEditContainersCss.directoryTitle,
                                    children: "Leads"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "When a box is checked below, an active provider, with agreement, will be eligible to receive leads. Changes are saved automatically.",
                                    className: ProviderEditContainersCss.settingsExplanation
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                          id: "resourceDownloadLeads--" + ID.toString(provider.id),
                                          size: "SM",
                                          checked: provider.settings.resourceDownloadLeads,
                                          containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                          onChange: (function (param) {
                                              $$Promise.wait(Api_ProviderSetting.update(provider.settings.id, {
                                                        field: "ResourceDownloadLeads",
                                                        value: !provider.settings.resourceDownloadLeads
                                                      }), (function (x) {
                                                      if (x.TAG === "Ok") {
                                                        return updateProvider(x._0);
                                                      } else {
                                                        return SentryLogger.error1({
                                                                    rootModule: "ProviderAllSettings",
                                                                    subModulePath: /* [] */0,
                                                                    value: "make",
                                                                    fullPath: "ProviderAllSettings.make"
                                                                  }, "ProviderSettings::Update::Error", [
                                                                    "Error",
                                                                    x._0
                                                                  ]);
                                                      }
                                                    }));
                                            }),
                                          children: JsxRuntime.jsx("div", {
                                                children: "Resource Downloads"
                                              })
                                        }),
                                    className: ProviderEditContainersCss.servicesRow
                                  })
                            ],
                            className: Cx.cx([ProviderEditContainersCss.settings])
                          })
                    ],
                    className: ProviderEditContainersCss.generalContainer
                  }),
              className: ProviderEditContainersCss.container
            });
}

var Css;

var make = ProviderAllSettings;

export {
  Css ,
  make ,
}
/* H3 Not a pure module */
